import api from '@e74/services/ApiConfig';
import {fetchStart, fetchSuccess, fetchError} from '../Common';
import {
  selectApplicableForms,
  selectPracticeNewPatientAgreementFile,
  selectPracticeNewPatientAgreementFileName,
  selectPracticeNewPatientAgreementSpanishFileName,
  selectPracticeNewPatientAgreementSpanishFile,
} from 'redux/reducers/admin/instance-settings';

export const ACTION_TYPES = {
  FETCH_ALL: 'FETCH_ALL_INSTANCE_SETTINGS',
  SET_DEFAULT_EMAIL_SENDER: 'SET_INSTANCE_SETTINGS_DEFAULT_EMAIL_SENDER',
  SET_DEFAULT_SMS_NUMBER: 'SET_INSTANCE_SETTINGS_DEFAULT_SMS_NUMBER',
  SET_CLINIC_NAME: 'SET_INSTANCE_SETTINGS_CLINIC_NAME',
  SET_DEFAULT_STATE: 'SET_INSTANCE_SETTINGS_DEFAULT_STATE',
  SET_ENABLE_SMS: 'SET_INSTANCE_SETTINGS_ENABLE_SMS',
  SET_ENABLE_EMAIL: 'SET_INSTANCE_SETTINGS_ENABLE_EMAIL',
  SET_ENABLE_PATIENT_SELF_CHECKIN:
    'SET_INSTANCE_SETTINGS_ENABLE_PATIENT_SELF_CHECKIN',
  SET_DEFAULT_RAND_FORM: 'SET_INSTANCE_SETTINGS_DEFAULT_RAND_FORM',
  SET_APPLICABLE_FORMS: 'SET_INSTANCE_SETTINGS_APPLICABLE_FORMS',
  SET_GOOGLE_REVIEW_LINK: 'SET_INSTANCE_SETTINGS_GOOGLE_REVIEW_LINK',
  SET_DEFAULT_PAIN_SCORE: 'SET_INSTANCE_DEFAULT_PAIN_SCORE',
  SET_TIME_ZONE_ID: 'SET_INSTANCE_SETTINGS_TIME_ZONE_ID',
  SET_PRO_RATE: 'SET_INSTANCE_SETTINGS_PRO_RATE',
  SET_RPM_RATE: 'SET_INSTANCE_SETTINGS_RPM_RATE',
  SET_CLINIC_LOGO_PRIMARY_FILE_NAME:
    'SET_INSTANCE_SETTINGS_CLINIC_LOGO_PRIMARY_FILE_NAME',
  SET_CLINIC_LOGO_PRIMARY_FILE:
    'SET_INSTANCE_SETTINGS_CLINIC_LOGO_PRIMARY_FILE',
  SET_CLINIC_LOGO_SECONDARY_FILE_NAME:
    'SET_INSTANCE_SETTINGS_CLINIC_LOGO_SECONDARY_FILE_NAME',
  SET_CLINIC_LOGO_SECONDARY_FILE:
    'SET_INSTANCE_SETTINGS_CLINIC_LOGO_SECONDARY_FILE',
  SET_PRACTICE_LOGO_FILE_NAME: 'SET_INSTANCE_SETTINGS_PRACTICE_LOGO_FILE_NAME',
  SET_PRACTICE_LOGO_FILE: 'SET_INSTANCE_SETTINGS_PRACTICE_LOGO_FILE',
  SET_TIME_ZONE_OFFSET_HOURS: 'SET_INSTANCE_SETTINGS_TIME_ZONE_OFFSET_HOURS',
  SET_PRACTICE_NEW_PATIENT_AGREEMENT_FILE:
    'SET_INSTANCE_SETTINGS_NEW_PATIENT_AGREEMENT_FILE',
  SET_PRACTICE_NEW_PATIENT_AGREEMENT_FILE_NAME:
    'SET_INSTANCE_SETTINGS_NEW_PATIENT_AGREEMENT_FILE_NAME',
  SET_USE_ATHENA: 'SET_INSTANCE_SETTINGS_USE_ATHENA',
  SET_ATHENA_PRACTICE_ID: 'SET_INSTANCE_SETTINGS_ATHENA_PRACTICE_ID',
  SET_ATHENA_DEPARTMENT_IDS: 'SET_INSTANCE_SETTINGS_ATHENA_DEPARTMENT_IDS',
  SET_EXTENDED_PATIENT_ASK_SEXUAL_ORIENTATION:
    'SET_INSTANCE_SETTINGS_EXTENDED_PATIENT_ASK_SEXUAL_ORIENTATION',
  SET_EXTENDED_PATIENT_ASK_ASSIGNED_SEX_AT_BIRTH:
    'SET_INSTANCE_SETTINGS_EXTENDED_PATIENT_ASK_ASSIGNED_SEX_AT_BIRTH',
  SET_EXTENDED_PATIENT_ASK_PREFERRED_PRONOUNS:
    'SET_INSTANCE_SETTINGS_EXTENDED_PATIENT_ASK_PREFERRED_PRONOUNS',
  SET_USE_RPM: 'SET_INSTANCE_SETTINGS_USE_RPM',
  SET_ATHENA_PROVIDER_IDS: 'SET_INSTANCE_SETTINGS_ATHENA_PROVIDER_IDS',
  SET_ATHENA_THERAPIST_IDS: 'SET_INSTANCE_SETTINGS_ATHENA_THERAPIST_IDS',
  SET_ATHENA_ORTHOPEDIC_IDS: 'SET_INSTANCE_SETTINGS_ATHENA_ORTHOPEDIC_IDS',
  SET_EXTENDED_PATIENT_ASK_COMMUNICATION_PREFERENCES:
    'SET_INSTANCE_SETTINGS_EXTENDED_PATIENT_ASK_COMMUNICATION_PREFERENCES',
  SET_SHOW_BMI_FILTER_ON_SANDBOX:
    'SET_INSTANCE_SETTINGS_SHOW_BMI_FILTER_ON_SANDBOX',
  SET_PATIENT_APPOINTMENT_REASON_ADDITIONAL_QUESTION_ID:
    'SET_INSTANCE_SETTINGS_PATIENT_APPOINTMENT_REASON_ADDITIONAL_QUESTION_ID',
  SET_PATIENT_APPOINTMENT_REASON_NEW_PATIENT_ADDITIONAL_QUESTION_OPTION_ID:
    'SET_INSTANCE_SETTINGS_PATIENT_APPOINTMENT_REASON_NEW_PATIENT_ADDITIONAL_QUESTION_OPTION_ID',
  SET_ADDITIONAL_QUESTION_IDS_TO_ONLY_SHOW_FOR_NEW_PATIENT_PATIENT_APPOINTMENT_REASON:
    'SET_INSTANCE_SETTINGS_ADDITIONAL_QUESTION_IDS_TO_ONLY_SHOW_FOR_NEW_PATIENT_PATIENT_APPOINTMENT_REASON',
  SET_INTAKE_FORM_NOTIFICATION_HOURS:
    'SET_INSTANCE_SETTINGS_INTAKE_FORM_NOTIFICATION_HOURS',
  SET_SHOW_INTAKE_FORM_FOR_NEW_PATIENTS:
    'SET_INSTANCE_SETTINGS_SHOW_INTAKE_FORM_FOR_NEW_PATIENTS',
  SET_PATIENT_APPOINTMENT_REASON_FOLLOW_UP_ADDITIONAL_QUESTION_OPTION_ID:
    'SET_INSTANCE_SETTINGS_PATIENT_APPOINTMENT_REASON_FOLLOW_UP_ADDITIONAL_QUESTION_OPTION_ID',
  SET_ENABLE_PAYMENT_COLLECTION:
    'SET_INSTANCE_SETTINGS_ENABLE_PAYMENT_COLLECTION',
  SET_SHOW_OPT_IN_TO_CONSENT_PAGE:
    'SET_INSTANCE_SETTINGS_SHOW_OPT_IN_TO_CONSENT_PAGE',
  SET_ENABLE_DEMO_DASHBOARD: 'SET_INSTANCE_SETTINGS_ENABLE_DEMO_DASHBOARD',
  SET_DEMO_PATIENT_ID: 'SET_INSTANCE_SETTINGS_DEMO_PATIENT_ID',
  SET_DEMO_PATIENT_APPOINTMENT_ID:
    'SET_INSTANCE_SETTINGS_DEMO_PATIENT_APPOINTMENT_ID',
  SET_DEMO_PATIENT_EXTERNAL_ID:
    'SET_INSTANCE_SETTINGS_DEMO_PATIENT_EXTERNAL_ID',
  SET_ENABLE_EDUCATIONAL_VIDEOS_PAGE:
    'SET_INSTANCE_SETTINGS_ENABLE_EDUCATIONAL_VIDEOS_PAGE',
  SET_PRACTICE_NEW_PATIENT_AGREEMENT_SPANISH_FILE:
    'SET_INSTANCE_SETTINGS_NEW_PATIENT_AGREEMENT_SPANISH_FILE',
  SET_PRACTICE_NEW_PATIENT_AGREEMENT_SPANISH_FILE_NAME:
    'SET_INSTANCE_SETTINGS_NEW_PATIENT_AGREEMENT_SPANISH_FILE_NAME',
  SET_REACTIV_DIAGNOSIS_CODES: 'SET_REACTIV_DIAGNOSIS_CODES',
  SET_REACTIV_OVERRIDE_DATE: 'SET_REACIV_OVERRIDE_DATE',
  SET_REACTIV_EMAIL_RECIPIENTS: 'SET_REACTIV_EMAIL_RECIPIENTS',
  SET_USE_REACTIV: 'SET_USE_REACTIV',
  SET_REACTIVE_PROVIDERS: 'SET_REACTIVE_PROVIDERS',
  SET_ENABLE_UDS_STATUS_REMINDER: 'SET_ENABLE_UDS_STATUS_REMINDER',
  SET_CLINIC_ADDRESS: 'SET_CLINIC_ADDRESS',
  SET_ENABLE_HOME_CHECKIN: 'SET_ENABLE_HOME_CHECKIN',
  SET_CLINIC_EMAIL: 'SET_CLINIC_EMAIL',
  SET_ENABLE_PROCEDURE_NOTE: 'SET_ENABLE_PROCEDURE_NOTE',
  SET_ENABLE_SHOT_CONSENT: 'SET_ENABLE_SHOT_CONSENT',
  SET_THERAPY_MANAGER_NUMBER: 'SET_THERAPY_MANAGER_NUMBER',
  SET_WELCOME_SCREEN_ENABLED: 'SET_WELCOME_SCREEN_ENABLED',
  SET_IS_PHARMACY_REQUIRED: 'SET_IS_PHARMACY_REQUIRED',
  SET_HIDE_EXIT_PRO: 'SET_HIDE_EXIT_PRO',
  SET_APPT_CANCEL_EMAILS: 'SET_APPT_CANCEL_EMAILS',
  SET_APPT_CANCEL_EMAIL_BODY: 'SET_APPT_CANCEL_EMAIL_BODY',
  SET_DEPLOYMENT_DATE: 'SET_DEPLOYMENT_DATE',
  SET_SKIP_PAYMENT_DEPARTMENTS: 'SET_SKIP_PAYMENT_DEPARTMENTS',
  SET_USE_MODMED: 'SET_INSTANCE_SETTINGS_USE_MODMED',
  SET_MODMED_ACCESS_TOKEN_URL: 'SET_INSTANCE_SETTINGS_MODMED_ACCESS_TOKEN_URL',
  SET_MODMED_RESOURCE_URL: 'SET_INSTANCE_SETTINGS_MODMED_RESOURCE_URL',
  SET_MODMED_USERNAME: 'SET_INSTANCE_SETTINGS_MODMED_USERNAME',
  SET_MODMED_PASSWORD: 'SET_INSTANCE_SETTINGS_MODMED_PASSWORD',
  SET_MODMED_APIKEY: 'SET_INSTANCE_SETTINGS_MODMED_APIKEY',
  SET_MODMED_DOC_MAPPING_DEFAULT:
    'SET_INSTANCE_SETTINGS_MODMED_DOC_MAPPING_DEFAULT',
  SET_MODMED_DOC_MAPPING_PATIENTAGREEMENT:
    'SET_INSTANCE_SETTINGS_MODMED_DOC_MAPPING_PATIENTAGREEMENT',
  SET_MODMED_DOC_MAPPING_ONEPAGER:
    'SET_INSTANCE_SETTINGS_MODMED_DOC_MAPPING_ONEPAGER',
  SET_MODMED_DOC_MAPPING_SCREENER:
    'SET_INSTANCE_SETTINGS_MODMED_DOC_MAPPING_SCREENER',
  SET_USE_LUCID_LANE: 'SET_USE_LUCID_LANE',
  SET_ORDER_TYPE_ID: 'SET_ORDER_TYPE_ID',
  SET_LUCID_LANE_NOTIFICATION_EMAIL: 'SET_LUCID_LANE_NOTIFICATION_EMAIL',
  SET_CONSENT_EXPIRATION: 'SET_CONSENT_EXPIRATION',
  SET_HEALTH_HISTORY_RECONSILIATION: 'SET_HEALTH_HISTORY_RECONSILIATION',
  SET_CUSTOM_FORM_SUBMISSION_EMAILS: 'SET_CUSTOM_FORM_SUBMISSION_EMAILS',
  SET_UPSIDE_APPOINTMENT_ID: 'SET_UPSIDE_APPOINTMENT_ID',
  SET_USE_NEVRO: 'SET_USE_NEVRO',
  SET_CLINIC_PHONENUMBER: 'SET_CLINIC_PHONENUMBER',
  SET_CHECKIN_CONFIRMATION_PAGE: 'SET_CHECKIN_CONFIRMATION_PAGE',
  SET_ENABLE_PROVIDER_APPOINTMENT_DASHBOARD:
    'SET_ENABLE_PROVIDER_APPOINTMENT_DASHBOARD',
  SET_USE_DRCHRONO: 'SET_INSTANCE_SETTINGS_USE_DRCHRONO',
  SET_DRCHRONO_CLIENT_ID: 'SET_INSTANCE_SETTINGS_DRCHRONO_CLIENT_ID',
  SET_DRCHRONO_CLIENT_SECRET: 'SET_INSTANCE_SETTINGS_DRCHRONO_CLIENT_SECRET',
  SET_DRCHRONO_REDIRECT_URL: 'SET_INSTANCE_SETTINGS_DRCHRONO_REDIRECT_URL',
  SET_DRCHRONO_WEBHOOK_SECRET: 'SET_INSTANCE_SETTINGS_DRCHRONO_WEBHOOK_SECRET',
  SET_USE_ADVANCEDMD: 'SET_INSTANCE_SETTINGS_USE_ADVANCEDMD',
  SET_ADVANCEDMD_USERNAME: 'SET_INSTANCE_SETTINGS_ADVANCEDMD_USERNAME',
  SET_ADVANCEDMD_PASSWORD: 'SET_INSTANCE_SETTINGS_ADVANCEDMD_PASSWORD',
  SET_ADVANCEDMD_OFFICE_CODE: 'SET_INSTANCE_SETTINGS_ADVANCEDMD_OFFICE_CODE',
  SET_ADVANCEDMD_APPNAME: 'SET_INSTANCE_SETTINGS_ADVANCEDMD_APPNAME',
  SET_ADVANCEDMD_INITIAL_URL: 'SET_INSTANCE_SETTINGS_ADVANCEDMD_INITIAL_URL',
  SET_ADVANCEDMD_TOKEN_DETAILS:
    'SET_INSTANCE_SETTINGS_ADVANCEDMD_TOKEN_DETAILS',
  SET_USE_MAINSTAY: 'SET_USE_MAINSTAY',
  SET_NEVRO_SALESFORCE_API_VERSION:
    'SET_INSTANCE_SETTINGS_NEVRO_SALESFORCE_API_VERSION',
  SET_NEVRO_SALESFORCE_CLIENT_ID:
    'SET_INSTANCE_SETTINGS_NEVRO_SALESFORCE_CLIENT_ID',
  SET_NEVRO_SALESFORCE_CLIENT_SECRET:
    'SET_INSTANCE_SETTINGS_NEVRO_SALESFORCE_CLIENT_SECRET',
  SET_NEVRO_SALESFORCE_TOKEN_URL:
    'SET_INSTANCE_SETTINGS_NEVRO_SALESFORCE_TOKEN_URL',
  SET_NEVRO_SALESFORCE_INTROSPECT_URL:
    'SET_INSTANCE_SETTINGS_NEVRO_SALESFORCE_INTROSPECT_URL',
  SET_ENABLE_PSYC_EVAL: 'SET_ENABLE_PSYC_EVAL',
  SET_ENABLE_MRN_LOOKUP: 'SET_ENABLE_MRN_LOOKUP',
  SET_CAMPAIGN_EMAILS: 'SET_CAMPAIGN_EMAILS',
  SET_IS_PORTAL_PRO_CLINIC: 'SET_IS_PORTAL_PRO_CLINIC',
  SET_USE_GREENWAY: 'SET_USE_GREENWAY',
  SET_GREENWAY_API_KEY: 'SET_GREENWAY_API_KEY',
  SET_GREENWAY_PRACTICE_ID: 'SET_GREENWAY_PRACTICE_ID',
  SET_GREENWAY_SERVICE_CENTER_IDS: 'SET_GREENWAY_SERVICE_CENTER_IDS',
  SET_GREENWAY_PROVIDER_IDS: 'SET_GREENWAY_PROVIDER_IDS',
  SET_GREENWAY_USERNAME: 'SET_GREENWAY_USERNAME',
  SET_GREENWAY_PASSWORD: 'SET_GREENWAY_PASSWORD',
  SET_GREENWAY_LICENSE_ID: 'SET_GREENWAY_LICENSE_ID',
  SET_GREENWAY_USER_LOGON: 'SET_GREENWAY_USER_LOGON',
  SET_GREENWAY_APPLICATION_NAME: 'SET_GREENWAY_APPLICAITON_NAME',
  SET_GREENWAY_MACHINE_NAME: 'SET_GREENWAY_MACHINE_NAME',
  SET_GREENWAY_DOC_MAPPING_DEFAULT:
    'SET_INSTANCE_SETTINGS_GREENWAY_DOC_MAPPING_DEFAULT',
  SET_GREENWAY_DOC_MAPPING_PATIENTAGREEMENT:
    'SET_INSTANCE_SETTINGS_GREENWAY_DOC_MAPPING_PATIENTAGREEMENT',
  SET_GREENWAY_DOC_MAPPING_ONEPAGER:
    'SET_INSTANCE_SETTINGS_GREENWAY_DOC_MAPPING_ONEPAGER',
  SET_GREENWAY_DOC_MAPPING_SCREENER:
    'SET_INSTANCE_SETTINGS_GREENWAY_DOC_MAPPING_SCREENER',
  SET_SHOW_NEW_APPOINTMENT_DASHBOARD: 'SET_SHOW_NEW_APPOINTMENT_DASHBOARD',
  SET_ENABLE_DIGITAL_SHOT_CONSENT: 'SET_ENABLE_DIGITAL_SHOT_CONSENT',
  SET_CUSTOM_QUESTION_DEPARTMENT_IDS: 'SET_CUSTOM_QUESTION_DEPARTMENT_IDS',
  SET_AUTO_LOGOUT_TIME: 'SET_AUTO_LOGOUT_TIME',
  USE_CODE_PULL: 'USE_CODE_PULL',
  SET_ENABLE_PORTAL_PRO: 'SET_ENABLE_PORTAL_PRO',
  SET_IS_PORTAL_PRO_CLINIC: 'SET_IS_PORTAL_PRO_CLINIC',
  SET_MAPPED_ALGOS_CLINIC_INSTANCE_ID: 'SET_MAPPED_ALGOS_CLINIC_INSTANCE_ID',
  SET_USE_ECLINICALWORKS: 'SET_USE_ECLINICALWORKS',
  SET_ECLINICALWORKS_CLIENT_ID: 'SET_ECLINICALWORKS_CLIENT_ID',
  SET_ECLINICALWORKS_CLIENT_SECRET: 'SET_ECLINICALWORKS_CLIENT_SECRET',
  SET_ECLINICALWORKS_RESOURCE_URL: 'SET_ECLINICALWORKS_RESOURCE_URL',
  SET_ECLINICALWORKS_REDIRECT_URL: 'SET_ECLINICALWORKS_REDIRECT_URL',
  SET_ECLINICALWORKS_BULK_CLIENT_ID: 'SET_ECLINICALWORKS_BULK_CLIENT_ID',
  SET_ECLINICALWORKS_BULK_GROUP_ID: 'SET_ECLINICALWORKS_BULK_GROUP_ID',
  SET_ECLINICALWORKS_BULK_PRIVATE_KEY_FILE_NAME:
    'SET_ECLINICALWORKS_BULK_PRIVATE_KEY_FILE_NAME',
  SET_ECLINICALWORKS_BULK_PRIVATE_KEY_FILE:
    'SET_ECLINICALWORKS_BULK_PRIVATE_KEY_FILE',
  SET_PREFER_ATHENA_FHIR: 'SET_PREFER_ATHENA_FHIR',
};

export const fetchAll = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchAll()
    .then((response) => {
      dispatch({type: ACTION_TYPES.FETCH_ALL, payload: response.data});
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updateAll = (onSuccess, onError) => (dispatch, getState) => {
  const currentState = getState().instanceSettings.data.list;
  dispatch(fetchStart());
  api
    .instanceSettings()
    .addOrUpdateAll({
      configurationValues: [
        currentState.defaultEmailSender, //1 - Default_Email_Sender
        currentState.defaultSmsNumber, //2 - Default_Sms_Number
        null, //3 - Practice_Name
        currentState.clinicName, //4 - Clinic_Name
        currentState.defaultState, //5 - Default_State
        String(currentState.enableSms), //6 - EnableSms
        String(currentState.enableEmail), //7 - EnableEmail
        currentState.defaultRandForm, //8 - DefaultRandForm
        null, //9 - ApplicableForms
        null, //10
        currentState.timeZoneId, //11 - Time_Zone
        currentState.proRate, //12 - Pro_Rate
        currentState.rpmRate, //13 - Rpm_Rate
        null, //14 - Clinic_Logo_Primary_File_Name
        null, //15 - Practice_Logo_File_Name
        null, //16 - Twilio_Channel_Id
        null, //17 - Clinic_Logo_Secondary_File_Name
        String(currentState.enablePatientSelfCheckin), //18 - Enable_Patient_Self_Checkin
        null, //19 - Practice_New_Patient_Agreement_File_Name
        String(currentState.useAthena), //20 - USE_ATHENA
        currentState.athenaPracticeId, //21 - ATHENA_PRACTICE_ID
        currentState.athenaDepartmentIds, //22 - ATHENA_DEPARTMENTS_IDS
        String(currentState.extendedPatientAskSexualOrientation), //23 - EXTENDED_PATIENT_ASK_SEXUAL_ORIENTATION
        String(currentState.extendedPatientAskAssignedSexAtBirth), //24 - EXTENDED_PATIENT_ASK_ASSIGNED_SEX_AT_BIRTH
        String(currentState.extendedPatientAskPreferredPronouns), //25 - EXTENDED_PATIENT_ASK_PREFERRED_PRONOUNS
        String(currentState.useRpm), //26 - USE_RPM
        currentState.athenaProviderIds, //27 - ATHENA_PROVIDER_IDS
        String(currentState.extendedPatientAskCommunicationPreferences), //28 - EXTENDED_PATIENT_ASK_COMMUNICATION_PREFERENCES
        String(currentState.showBmiFilterOnSandbox), //29 - SHOW_BMI_FILTER_ON_SANDBOX
        currentState.patientAppointmentReasonAdditionalQuestionId, //30 - PATIENT_APPOINTMENT_REASON_ADDITIONAL_QUESTION_ID
        currentState.patientAppointmentReasonNewPatientAdditionalQuestionOptionId, //31 - PATIENT_APPOINTMENT_REASON_NEW_PATIENT_ADDITIONAL_QUESTION_OPTION_ID
        currentState.additionalQuestionIdsToOnlyShowForNewPatientPatientAppointmentReason, //32 - ADDITIONAL_QUESTION_IDS_TO_ONLY_SHOW_FOR_NEW_PATIENT_PATIENT_APPOINTMENT_REASON
        currentState.intakeFormNotificationHours, //33 - INTAKE_FORM_NOTIFICATION_HOURS
        String(currentState.showIntakeFormForNewPatients), //34 - SHOW_INTAKE_FORM_FOR_NEW_PATIENTS
        currentState.patientAppointmentReasonFollowUpAdditionalQuestionOptionId, //35 - PATIENT_APPOINTMENT_REASON_FOLLOW_UP_ADDITIONAL_QUESTION_OPTION_ID
        String(currentState.showOptInToConsentPage), //36 - SHOW_OPT_IN_TO_CONSENT_PAGE
        String(currentState.enablePaymentCollection), //37 - ENABLE_PAYMENT_COLLECTION
        String(currentState.enableDemoDashboard), //38 - ENABLE_DEMO_DASHBOARD
        currentState.demoPatientId, //39 - DEMO_PATIENT_ID
        currentState.demoPatientAppointmentId, //40 - DEMO_PATIENT_APPOINTMENT_ID
        currentState.demoPatientExternalId, //41 - DEMO_PATIENT_EXTERNAL_ID
        String(currentState.enableEducationalVideosPage), //42 - ENABLE_EDUCATIONAL_VIDEOS_PAGE
        currentState.athenaTherapistIds, //43 - THERAPIST_PROVIDER_IDS
        null, //44 - PRACTICE_NEW_PATIENT_AGREEMENT_FILE_NAME_SPANISH
        String(currentState.enableUdsStatusReminder), //45 - MEDICAL_SCREENER_REMINDERS
        currentState.athenaOrthopedicIds, //46 - ORTHOPEDIC_PROVIDER_IDS
        null, //47 - USE_REACTIV8
        currentState.reactivDiagnosisCodes, //48 - REACTIV8_DIAGNOSIS_CODES
        currentState.reactivOverrideDate, //49 - REACTIV8_OVERRIDE_DATE
        currentState.reactivEmailRecipients, //50 - REACTIV8_EMAIL_RECIPIENTS
        currentState.reactivProviders, //51 - REACTIV8_PROVIDERS
        currentState.clinicAddress, //52 - CLINIC_ADDRESS
        String(currentState.enableHomeCheckin), //53 - ENABLE_HOME_CHECKIN
        currentState.clinicEmail, //54 - CLINIC_EMAIL
        String(currentState.enableProcedureNote), //55 - ENABLE_PROCEDURE_NOTE
        String(currentState.enableShotConsent), //56 - ENABLE_SHOT_CONSENT
        currentState.therapyManagerNumber, //57 - THERAPY_MANAGER_NUMBER
        String(currentState.welcomeScreenEnabled), //58 - ENABLE_WELCOME_SCREEN
        String(currentState.isPharmacyRequired), //59 - IS_PHARMACY_REQUIRED
        String(currentState.hideExitPro), //60 - Hide Exit Pro
        currentState.apptCancelEmails, //61 - APPOINTMENT_CANCELLATION_EMAILS
        currentState.apptCancelEmailBody, //62 - APPOINTMENT_CANCELLATION_EMAIL_BODY
        currentState.deploymentDate, //63 - Deployment Date
        currentState.skipPaymentDepartments, //64 - Skip Payment Departments
        String(currentState.useModMed), //65 - USE_MODMED
        currentState.modMedAccessTokenUrl, //66 - MODMED_ACCESS_TOKEN_URL
        currentState.modMedResourceUrl, //67 - MODMED_RESOURCE_URL
        currentState.modMedUsername, //68 - MODMED_USERNAME
        currentState.modMedPassword, //69 - MODMED_PASSWORD
        currentState.modMedApiKey, //70 - MODMED_APIKEY
        currentState.consentExpiration, //71 - CONSENT_EXPIRATION
        currentState.healthHistoryReconsiliation, //72 - HEALTH_HISTORY_RECONSILIATION
        currentState.modMedDocMappingDefault, //73 - MODMED_DOC_MAPPING_DEFAULT
        currentState.modMedDocMappingPatientAgreement, //74 - MODMED_DOC_MAPPING_PATIENTAGREEMENT
        String(currentState.useLucidLane), //75 - USE_LUCID_LANE
        currentState.orderTypeId, // 76 - ORDER_TYPE_ID
        currentState.lucidLaneNotificationEmail, // 77 - LUCID_LANE_NOTIFICATION_EMAIL
        currentState.upsideAppointmentId, //78 - Upside AppointmentId
        currentState.modMedDocMappingOnePager, // 79 - MODMED_DOC_MAPPING_ONEPAGER
        currentState.modMedDocMappingScreener, //80 - MODMED_DOC_MAPPING_SCREENER
        String(currentState.useDrChrono), // 81 - USE_DRCHRONO
        currentState.drChronoClientId, // 82 - DRCHRONO_CLIENT_ID
        currentState.drChronoClientSecret, // 83 - DRCHRONO_CLIENT_SECRET
        currentState.drChronoRedirectUrl, // 84 - DRCHRONO_REDIRECT_URL
        null, // 85 - DRCHRONO_TOKEN_DETAILS,
        currentState.drChronoWebhookSecret, // 86 - DRCHRONO_WEBHOOK_SECRET
        currentState.customFormSubmissionEmails, //87 - Custom Form Submission Email
        String(currentState.checkinConfirmationPage), //88 - Checkin Confirmation Page
        currentState.enableProviderAppointmentDashboard, // 89 - SET_MONTHLY_APPOINTMENTS_BASELINE
        String(currentState.defaultPainScore), //90 - DEFAULT_PAIN_SCORE
        currentState.clinicPhoneNumber, //91 - CLINIC_PHONENUMBER
        String(currentState.useMainstay), //92 - USE_MAINSTAY,
        String(currentState.useNevro), //93 - USE_NEVRO,
        String(currentState.useAdvancedMD), //94 - USE_ADVANCEDMD
        currentState.advancedMDUsername, //95 - ADVANCEDMD_USERNAME
        currentState.advancedMDPassword, //96 - ADVANCEDMD_PASSWORD
        currentState.advancedMDOfficeCode, //97 - ADVANCEDMD_OFFICE_CODE
        currentState.advancedMDAppName, //98 - ADVANCEDMD_APPNAME
        currentState.advancedMDInitialUrl, //99 - ADVANCEDMD_INITIAL_URL
        null, //100 - ADVANCEDMD_TOKEN_DETAILS
        currentState.nevroSalesforceApiVersion, //101 - NEVRO_SALESFORCE_API_VERSION
        currentState.nevroSalesforceClientId, //102 - NEVRO_SALESFORCE_CLIENT_ID
        currentState.nevroSalesforceClientSecret, //103 - NEVRO_SALESFORCE_CLIENT_SECRET
        currentState.nevroSalesforceTokenUrl, //104 - NEVRO_SALESFORCE_TOKEN_URL
        currentState.nevroSalesforceIntrospectUrl, //105 - NEVRO_SALESFORCE_INTROSPECT_URL
        null, // 106 - NEVRO_SALESFORCE_TOKEN_DETAILS,
        String(currentState.newAppointmentDashboard), //107 - NEW_APPOINTMENT_DASHBOARD
        String(currentState.enableDigitalShotConsent), //108 - ENABLE_DIGITAL_SHOT_CONSENT
        null, //109 - CLINIC_NPI
        null, //110 - CLINIC_PTAN
        null, //111 - CLINIC_TAX_ID
        null, // 112 - CLINIC_CITY
        null, // 113 - CLINIC_STATE
        null, // 114 - CLINIC_ZIP_CODE
        null, // 115 - CLINIC_APPARTMENT
        String(currentState.useGreenway), //116 - USE_GREENWAY
        currentState.greenwayApiKey, //117 - GREENWAY_API_KEY
        currentState.greenwayPracticeId, //118 - GREENWAY_PRACTICE_ID
        currentState.greenwayServiceCenterIds, //119 - GREENWAY_SERVICE_CENTER_IDS
        currentState.greenwayProviderIds, //120 - GREENWAY_PROVIDER_IDS
        currentState.greenwayUsername, //121 - GREENWAY_USERNAME
        currentState.greenwayPassword, //122 - GREENWAY_PASSWORD
        currentState.greenwayLicenseId, //123 - GREENWAY_LICENSE_ID
        currentState.greenwayUserLogon, //124 - GREENWAY_USER_LOGON
        currentState.greenwayApplicationName, //125 - GREENWAY_APPLICATION_NAME
        currentState.greenwayMachineName, //126 - GREENWAY_MACHINE_NAME
        currentState.greenwayDocMappingDefault, //127 - GREENWAY_DOC_MAPPING_DEFAULT
        currentState.greenwayDocMappingPatientAgreement, //128 - GREENWAY_DOC_MAPPING_PATIENTAGREEMENT
        currentState.customQuestionDepartmentIds, // 129 - CUSTOM_QUESTION_DEPARTMENT_IDS
        currentState.autoLogoutTime, //130 - AUTO_LOGOUT_TIME
        String(currentState.useCodePull), // 131 - USE_CODE_PULL
        currentState.greenwayDocMappingOnePager, // 132 - GREENWAY_DOC_MAPPING_ONEPAGER
        String(currentState.enablePortalPro), //133 - ENABLE_PORTAL_PRO
        currentState.greenwayDocMappingScreener, //134 - GREENWAY_DOC_MAPPING_SCREENER
        null, //135 - GREENWAY_SESSION_DETAILS,
        String(currentState.enableMrnLookup), //136 - ENABLE_MRN_LOOKUP
        String(currentState.enablePsycEval), //137 - ENABLE_PSYC_EVAL
        null, //138 - SEND_SCHEDULING_DATA_TO_SALESFORCE
        currentState.campaignEmails, //139 - CAMPAIGN_EMAILS
        currentState.mappedAlgosClinicInstanceId, //140 - MAPPED_ALGOS_CLINIC_INSTANCE_ID
        String(currentState.useEClinicalWorks), //141 - USE_ECLINICALWORKS
        currentState.eclinicalWorksClientId, //142 - ECLINICALWORKS_CLIENT_ID
        currentState.eclinicalWorksClientSecret, //143 - ECLINICALWORKS_CLIENT_SECRET
        currentState.eclinicalWorksResourceUrl, //144 - ECLINICALWORKS_RESOURCE_URL
        currentState.eclinicalWorksRedirectUrl, //145 - ECLINICALWORKS_REDIRECT_URL,
        null, //146 - ECLINICALWORKS_CODE_VERIFIER
        null, //147 - ECLINICALWORKS_CODE_CHALLENGE
        null, //148 - ECLINICALWORKS_TOKEN_DETAILS
        currentState.eclinicalWorksBulkClientId, //149 - ECLINICALWORKS_BULK_CLIENT_ID
        currentState.eclinicalWorksBulkGroupId, //150 - ECLINICALWORKS_BULK_GROUP_ID
        null, //151 - ECLINICALWORKS_BULK_PRIVATE_KEY_FILE_NAME
        String(currentState.preferAthenaFHIR), //152 - SET_PREFER_ATHENA_FHIR
      ],
    })
    .then((res) => {
      onSuccess && onSuccess(res);
      dispatch(fetchSuccess());
      dispatch(fetchAll());
      dispatch(fetchTimeZoneOffsetHours());
    })
    .catch((err) => {
      onError && onError(err);
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err));
    });
};

export const updateApplicableForms = () => (dispatch, getState) => {
  dispatch(fetchStart());

  api
    .instanceSettings()
    .addOrUpdateApplicableForms(selectApplicableForms(getState()).map(String))
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updateClinicLogoPrimary = () => (dispatch, getState) => {
  const formData = new FormData();
  formData.append(
    'clinicLogoPrimaryFileName',
    getState().instanceSettings.data.list.clinicLogoPrimaryFileName,
  );
  formData.append(
    'clinicLogoPrimaryFile',
    getState().instanceSettings.data.list.clinicLogoPrimaryFile,
  );
  dispatch(fetchStart());
  api
    .instanceSettings()
    .updateClinicLogoPrimary(formData)
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const deleteClinicLogoPrimary = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .deleteClinicLogoPrimary()
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updateClinicLogoSecondary = () => (dispatch, getState) => {
  const formData = new FormData();
  formData.append(
    'clinicLogoSecondaryFileName',
    getState().instanceSettings.data.list.clinicLogoSecondaryFileName,
  );
  formData.append(
    'clinicLogoSecondaryFile',
    getState().instanceSettings.data.list.clinicLogoSecondaryFile,
  );
  dispatch(fetchStart());
  api
    .instanceSettings()
    .updateClinicLogoSecondary(formData)
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const deleteClinicLogoSecondary = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .deleteClinicLogoSecondary()
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updatePracticeLogo = () => (dispatch, getState) => {
  const formData = new FormData();
  formData.append(
    'practiceLogoFileName',
    getState().instanceSettings.data.list.practiceLogoFileName,
  );
  formData.append(
    'practiceLogoFile',
    getState().instanceSettings.data.list.practiceLogoFile,
  );
  dispatch(fetchStart());
  api
    .instanceSettings()
    .updatePracticeLogo(formData)
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const deletePracticeLogo = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .deletePracticeLogo()
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updatePracticeNewPatientAgreement = () => (dispatch, getState) => {
  const formData = new FormData();
  formData.append(
    'practiceNewPatientAgreementFileName',
    selectPracticeNewPatientAgreementFileName(getState()),
  );
  formData.append(
    'practiceNewPatientAgreementFile',
    selectPracticeNewPatientAgreementFile(getState()),
  );

  dispatch(fetchStart());
  api
    .instanceSettings()
    .updatePracticeNewPatientAgreement(formData)
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updatePracticeNewPatientAgreementSpanish =
  () => (dispatch, getState) => {
    const formData = new FormData();
    formData.append(
      'practiceNewPatientAgreementSpanishFileName',
      selectPracticeNewPatientAgreementSpanishFileName(getState()),
    );
    formData.append(
      'practiceNewPatientAgreementSpanishFile',
      selectPracticeNewPatientAgreementSpanishFile(getState()),
    );
    dispatch(fetchStart());
    api
      .instanceSettings()
      .updatePracticeNewPatientAgreementSpanish(formData)
      .then(() => {
        dispatch(fetchSuccess());
        dispatch(fetchAll());
      })
      .catch((err) =>
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err)),
      );
  };

export const deletePracticeNewPatientAgreement = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .deletePracticeNewPatientAgreement()
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const deletePracticeNewPatientAgreementSpanish = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .deletePracticeNewPatientAgreementSpanish()
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchEnablePatientSelfCheckin = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchEnablePatientSelfCheckin()
    .then((response) => {
      dispatch(setEnablePatientSelfCheckin(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchApplicableForms = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchApplicableForms()
    .then((response) => {
      dispatch(setApplicableForms(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchTimeZoneOffsetHours = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .getTimeZoneOffsetHours()
    .then((response) => {
      dispatch(setTimeZoneOffsetHours(response.data));
      localStorage.setItem('timeZoneOffsetHours', response.data);
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchPracticeNewPatientAgreementFileName = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .getPracticeNewPatientAgreementFileName()
    .then((response) => {
      dispatch(setPracticeNewPatientAgreementFileName(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchPracticeNewPatientAgreementSpanishFileName =
  () => (dispatch) => {
    dispatch(fetchStart());
    api
      .instanceSettings()
      .getPracticeNewPatientAgreementSpanishFileName()
      .then((response) => {
        dispatch(setPracticeNewPatientAgreementSpanishFileName(response.data));
        dispatch(fetchSuccess());
      })
      .catch((err) =>
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err)),
      );
  };

export const fetchUseAthena = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseAthena()
    .then((response) => {
      dispatch(setUseAthena(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchUseRpm = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseRpm()
    .then((response) => {
      dispatch(setUseRpm(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchExtendedPatientAskSexualOrientation = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchExtendedPatientAskSexualOrientation()
    .then((response) => {
      dispatch(setExtendedPatientAskSexualOrientation(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchExtendedPatientAskAssignedSexAtBirth = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchExtendedPatientAskAssignedSexAtBirth()
    .then((response) => {
      dispatch(setExtendedPatientAskAssignedSexAtBirth(response.data.sex));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};
export const fetchExtendedPatientAskPreferredPronouns = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchExtendedPatientAskPreferredPronouns()
    .then((response) => {
      dispatch(setExtendedPatientAskPreferredPronouns(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchClinicName = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchClinicName()
    .then((response) => {
      dispatch(setClinicName(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchExtendedPatientAskCommunicationPreferences =
  () => (dispatch) => {
    dispatch(fetchStart());
    api
      .instanceSettings()
      .fetchExtendedPatientAskCommunicationPreferences()
      .then((response) => {
        dispatch(setExtendedPatientAskCommunicationPreferences(response.data));
        dispatch(fetchSuccess());
      })
      .catch((err) =>
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err)),
      );
  };

export const fetchShowBmiFilterOnSandbox = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchShowBmiFilterOnSandbox()
    .then((response) => {
      dispatch(setShowBmiFilterOnSandbox(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchShowIntakeFormForNewPatients = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchShowIntakeFormForNewPatients()
    .then((response) => {
      dispatch(setShowIntakeFormForNewPatients(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchUseNevro = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseNevro()
    .then((response) => {
      dispatch(setUseNevro(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchUseCodePull = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseCodePull()
    .then((response) => {
      dispatch(setCodePull(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchUseModMed = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseModMed()
    .then((response) => {
      dispatch(setUseModMed(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchUseDrChrono = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseDrChrono()
    .then((response) => {
      dispatch(setUseDrChrono(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchUseMainstay = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseMainstay()
    .then((response) => {
      dispatch(setUseMainstay(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const fetchUseAdvancedMD = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseAdvancedMD()
    .then((response) => {
      dispatch(setUseAdvancedMD(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const IsPortalProClinic = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .isPortalProClinic()
    .then((response) => {
      dispatch(setIsPortalProClinic(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) => {});
};

export const fetchUseGreenway = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseGreenway()
    .then((response) => {
      dispatch(setUseGreenway(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const GetMrnLookupEnabled = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .getMrnLookupEnabled()
    .then((response) => {
      dispatch(setEnableMrnLookup(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) => {});
};

export const GetPsycEvalEnabled = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .getPsycEvalEnabled()
    .then((response) => {
      dispatch(setEnablePsycEval(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) => {});
};

export const fetchUseEClinicalWorks = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .fetchUseEClinicalWorks()
    .then((response) => {
      dispatch(setUseEClinicalWorks(response.data));
      dispatch(fetchSuccess());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const updateEClinicalWorksBulkPrivateKeyFile =
  () => (dispatch, getState) => {
    const formData = new FormData();
    formData.append(
      'privateKeyFileName',
      getState().instanceSettings.data.list
        .eclinicalWorksBulkPrivateKeyFileName,
    );
    formData.append(
      'privateKeyFile',
      getState().instanceSettings.data.list.eclinicalWorksBulkPrivateKeyFile,
    );
    dispatch(fetchStart());
    api
      .instanceSettings()
      .updateEClinicalWorksBulkPrivateKeyFile(formData)
      .then(() => {
        dispatch(fetchSuccess());
        dispatch(fetchAll());
      })
      .catch((err) =>
        err.response !== undefined
          ? dispatch(fetchError(err.response.data.error))
          : dispatch(fetchError(err)),
      );
  };

export const deleteEClinicalWorksBulkPrivateKeyFile = () => (dispatch) => {
  dispatch(fetchStart());
  api
    .instanceSettings()
    .deleteEClinicalWorksBulkPrivateKeyFile()
    .then(() => {
      dispatch(fetchSuccess());
      dispatch(fetchAll());
    })
    .catch((err) =>
      err.response !== undefined
        ? dispatch(fetchError(err.response.data.error))
        : dispatch(fetchError(err)),
    );
};

export const setDefaultEmailSender = (newEmailSender) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEFAULT_EMAIL_SENDER,
    payload: newEmailSender,
  });
};

export const setDefaultSmsNumber = (newSmsNumber) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEFAULT_SMS_NUMBER,
    payload: newSmsNumber,
  });
};

export const setClinicName = (newClinicName) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CLINIC_NAME,
    payload: newClinicName,
  });
};

export const setDefaultState = (newDefaultState) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEFAULT_STATE,
    payload: newDefaultState,
  });
};

export const setEnableSms = (newEnableSms) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_SMS,
    payload: newEnableSms,
  });
};

export const setEnableEmail = (newEnableEmail) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_EMAIL,
    payload: newEnableEmail,
  });
};

export const setEnablePatientSelfCheckin =
  (newEnablePatientSelfCheckin) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_ENABLE_PATIENT_SELF_CHECKIN,
      payload: newEnablePatientSelfCheckin,
    });
  };

export const setDefaultRandForm = (newDefaultRandForm) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEFAULT_RAND_FORM,
    payload: newDefaultRandForm,
  });
};

export const setApplicableForms = (newApplicableForms) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_APPLICABLE_FORMS,
    payload: newApplicableForms,
  });
};

export const setDefaultPainScore = (newDefaultPainScore) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEFAULT_PAIN_SCORE,
    payload: newDefaultPainScore,
  });
};

export const setTimeZoneId = (newTimeZoneId) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_TIME_ZONE_ID,
    payload: newTimeZoneId,
  });
};

export const setProRate = (newProRate) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_PRO_RATE,
    payload: newProRate,
  });
};

export const setRpmRate = (newRpmRate) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_RPM_RATE,
    payload: newRpmRate,
  });
};

export const setClinicLogoPrimaryFileName =
  (newClinicLogoPrimaryFileName) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_CLINIC_LOGO_PRIMARY_FILE_NAME,
      payload: newClinicLogoPrimaryFileName,
    });
  };

export const setClinicLogoPrimaryFile =
  (newClinicLogoPrimaryFile) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_CLINIC_LOGO_PRIMARY_FILE,
      payload: newClinicLogoPrimaryFile,
    });
  };

export const setClinicLogoSecondaryFileName =
  (newClinicLogoSecondaryFileName) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_CLINIC_LOGO_SECONDARY_FILE_NAME,
      payload: newClinicLogoSecondaryFileName,
    });
  };

export const setClinicLogoSecondaryFile =
  (newClinicLogoSecondaryFile) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_CLINIC_LOGO_SECONDARY_FILE,
      payload: newClinicLogoSecondaryFile,
    });
  };

export const setPracticeLogoFileName =
  (newPracticeLogoFileName) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PRACTICE_LOGO_FILE_NAME,
      payload: newPracticeLogoFileName,
    });
  };

export const setPracticeLogoFile = (newPracticeLogoFile) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_PRACTICE_LOGO_FILE,
    payload: newPracticeLogoFile,
  });
};

export const setTimeZoneOffsetHours =
  (newTimeZoneOffsetHours) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_TIME_ZONE_OFFSET_HOURS,
      payload: newTimeZoneOffsetHours,
    });
  };

export const setPracticeNewPatientAgreementFileName =
  (newPracticeNewPatientAgreementFileName) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PRACTICE_NEW_PATIENT_AGREEMENT_FILE_NAME,
      payload: newPracticeNewPatientAgreementFileName,
    });
  };

export const setPracticeNewPatientAgreementFile =
  (newPracticeNewPatientAgreementFile) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PRACTICE_NEW_PATIENT_AGREEMENT_FILE,
      payload: newPracticeNewPatientAgreementFile,
    });
  };

//Spanish agreement

export const setPracticeNewPatientAgreementSpanishFileName =
  (newPracticeNewPatientAgreementSpanishFileName) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PRACTICE_NEW_PATIENT_AGREEMENT_SPANISH_FILE_NAME,
      payload: newPracticeNewPatientAgreementSpanishFileName,
    });
  };

export const setPracticeNewPatientAgreementSpanishFile =
  (newPracticeNewPatientAgreementSpanishFile) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PRACTICE_NEW_PATIENT_AGREEMENT_SPANISH_FILE,
      payload: newPracticeNewPatientAgreementSpanishFile,
    });
  };

export const setUseAthena = (newUseAthena) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_ATHENA,
    payload: newUseAthena,
  });
};

export const setUseRpm = (newUseAthena) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_RPM,
    payload: newUseAthena,
  });
};

export const setAthenaPracticeId = (newAthenaPracticeId) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ATHENA_PRACTICE_ID,
    payload: newAthenaPracticeId,
  });
};

export const setAthenaDepartmentIds =
  (newAthenaDepartmentIds) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_ATHENA_DEPARTMENT_IDS,
      payload: newAthenaDepartmentIds,
    });
  };

export const setExtendedPatientAskSexualOrientation =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_EXTENDED_PATIENT_ASK_SEXUAL_ORIENTATION,
      payload: newValue,
    });
  };
export const setExtendedPatientAskAssignedSexAtBirth =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_EXTENDED_PATIENT_ASK_ASSIGNED_SEX_AT_BIRTH,
      payload: newValue,
    });
  };
export const setExtendedPatientAskPreferredPronouns =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_EXTENDED_PATIENT_ASK_PREFERRED_PRONOUNS,
      payload: newValue,
    });
  };

export const setAthenaProviderIds = (newAthenaProviderIds) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ATHENA_PROVIDER_IDS,
    payload: newAthenaProviderIds,
  });
};

export const setExtendedPatientAskCommunicationPreferences =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_EXTENDED_PATIENT_ASK_COMMUNICATION_PREFERENCES,
      payload: newValue,
    });
  };

export const setShowBmiFilterOnSandbox = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SHOW_BMI_FILTER_ON_SANDBOX,
    payload: newValue,
  });
};

export const setPatientAppointmentReasonAdditionalQuestionId =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PATIENT_APPOINTMENT_REASON_ADDITIONAL_QUESTION_ID,
      payload: newValue,
    });
  };

export const setPatientAppointmentReasonNewPatientAdditionalQuestionOptionId =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PATIENT_APPOINTMENT_REASON_NEW_PATIENT_ADDITIONAL_QUESTION_OPTION_ID,
      payload: newValue,
    });
  };

export const setAdditionalQuestionIdsToOnlyShowForNewPatientPatientAppointmentReason =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_ADDITIONAL_QUESTION_IDS_TO_ONLY_SHOW_FOR_NEW_PATIENT_PATIENT_APPOINTMENT_REASON,
      payload: newValue,
    });
  };

export const setIntakeFormNotificationHours = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_INTAKE_FORM_NOTIFICATION_HOURS,
    payload: newValue,
  });
};

export const setShowIntakeFormForNewPatients = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SHOW_INTAKE_FORM_FOR_NEW_PATIENTS,
    payload: newValue,
  });
};

export const setPatientAppointmentReasonFollowUpAdditionalQuestionOptionId =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PATIENT_APPOINTMENT_REASON_FOLLOW_UP_ADDITIONAL_QUESTION_OPTION_ID,
      payload: newValue,
    });
  };

export const setEnablePaymentCollection = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_PAYMENT_COLLECTION,
    payload: newValue,
  });
};
export const setShowOptInToConsentPage = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SHOW_OPT_IN_TO_CONSENT_PAGE,
    payload: newValue,
  });
};

export const setEnableDemoDashboard = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_DEMO_DASHBOARD,
    payload: newValue,
  });
};

export const setDemoPatientId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEMO_PATIENT_ID,
    payload: newValue,
  });
};

export const setDemoPatientAppointmentId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEMO_PATIENT_APPOINTMENT_ID,
    payload: newValue,
  });
};

export const setDemoPatientExternalId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEMO_PATIENT_EXTERNAL_ID,
    payload: newValue,
  });
};

export const setEnableEducationalVideosPage = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_EDUCATIONAL_VIDEOS_PAGE,
    payload: newValue,
  });
};

export const setAthenaTherapistIds = (newAthenaTherapistIds) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ATHENA_THERAPIST_IDS,
    payload: newAthenaTherapistIds,
  });
};

export const setAthenaOrthopedicIds =
  (newAthenaOrthopedicIds) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_ATHENA_ORTHOPEDIC_IDS,
      payload: newAthenaOrthopedicIds,
    });
  };

export const setEnableUdsStatusReminder = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_UDS_STATUS_REMINDER,
    payload: newValue,
  });
};

export const setReactivDiagnosisCodes = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_REACTIV_DIAGNOSIS_CODES,
    payload: newValue,
  });
};

export const setReactivOverrideDate = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_REACTIV_OVERRIDE_DATE,
    payload: newValue,
  });
};

export const setReactivEmailRecipients = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_REACTIV_EMAIL_RECIPIENTS,
    payload: newValue,
  });
};

export const setUseReactiv = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_REACTIV,
    payload: newValue,
  });
};

export const setReactivProviders = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_REACTIVE_PROVIDERS,
    payload: newValue,
  });
};

export const setClinicAddress = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CLINIC_ADDRESS,
    payload: newValue,
  });
};

export const setEnableHomeCheckin = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_HOME_CHECKIN,
    payload: newValue,
  });
};

export const setClinicEmail = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CLINIC_EMAIL,
    payload: newValue,
  });
};

export const setEnableProcedureNote = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_PROCEDURE_NOTE,
    payload: newValue,
  });
};

export const setEnableShotConsent = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_SHOT_CONSENT,
    payload: newValue,
  });
};

export const setTherapyManagerNumber = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_THERAPY_MANAGER_NUMBER,
    payload: newValue,
  });
};

export const setWelcomeScreenEnabled = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_WELCOME_SCREEN_ENABLED,
    payload: newValue,
  });
};

export const setIsPharmacyRequired = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_IS_PHARMACY_REQUIRED,
    payload: newValue,
  });
};

export const setHideExitPro = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_HIDE_EXIT_PRO,
    payload: newValue,
  });
};

export const setApptCancelEmails = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_APPT_CANCEL_EMAILS,
    payload: newValue,
  });
};

export const setApptCancelEmailBody = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_APPT_CANCEL_EMAIL_BODY,
    payload: newValue,
  });
};

export const setDeploymentDate = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DEPLOYMENT_DATE,
    payload: newValue,
  });
};

export const setSkipPaymentDepartments = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SKIP_PAYMENT_DEPARTMENTS,
    payload: newValue,
  });
};

export const setUseModMed = (newUseModMed) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_MODMED,
    payload: newUseModMed,
  });
};

export const setModMedAccessTokenUrl =
  (newModMedAccessTokenUrl) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_MODMED_ACCESS_TOKEN_URL,
      payload: newModMedAccessTokenUrl,
    });
  };

export const setModMedResourceUrl = (newModMedResourceUrl) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_MODMED_RESOURCE_URL,
    payload: newModMedResourceUrl,
  });
};

export const setModMedUsername = (newModMedUsername) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_MODMED_USERNAME,
    payload: newModMedUsername,
  });
};

export const setModMedPassword = (newModMedPassword) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_MODMED_PASSWORD,
    payload: newModMedPassword,
  });
};

export const setModMedApiKey = (newModApiKey) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_MODMED_APIKEY,
    payload: newModApiKey,
  });
};

export const setModMedDocMappingDefault =
  (newModDocMappingDefault) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_MODMED_DOC_MAPPING_DEFAULT,
      payload: newModDocMappingDefault,
    });
  };

export const setModMedDocMappingPatientAgreement =
  (newModDocMappingPatientAgreement) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_MODMED_DOC_MAPPING_PATIENTAGREEMENT,
      payload: newModDocMappingPatientAgreement,
    });
  };

export const setModMedDocMappingOnePager =
  (newModDocMappingOnePager) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_MODMED_DOC_MAPPING_ONEPAGER,
      payload: newModDocMappingOnePager,
    });
  };

export const setModMedDocMappingScreener =
  (newModDocMappingScreener) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_MODMED_DOC_MAPPING_SCREENER,
      payload: newModDocMappingScreener,
    });
  };
export const setConsentExpiration = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CONSENT_EXPIRATION,
    payload: newValue,
  });
};

export const setHealthHistoryReconsiliation = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_HEALTH_HISTORY_RECONSILIATION,
    payload: newValue,
  });
};

export const setUseLucidLane = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_LUCID_LANE,
    payload: newValue,
  });
};

export const setOrderTypeId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ORDER_TYPE_ID,
    payload: newValue,
  });
};

export const setLucidLaneNotificationEmail = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_LUCID_LANE_NOTIFICATION_EMAIL,
    payload: newValue,
  });
};

export const setCustomFormSubmissionEmails = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CUSTOM_FORM_SUBMISSION_EMAILS,
    payload: newValue,
  });
};

export const setUpsideAppointmentId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_UPSIDE_APPOINTMENT_ID,
    payload: newValue,
  });
};

export const setCheckinConfirmationPage = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CHECKIN_CONFIRMATION_PAGE,
    payload: newValue,
  });
};

export const setEnableProviderAppointmentDashboard =
  (newValue) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_ENABLE_PROVIDER_APPOINTMENT_DASHBOARD,
      payload: newValue,
    });
  };

export const setUseDrChrono = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_DRCHRONO,
    payload: newValue,
  });
};

export const setDrChronoClientId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DRCHRONO_CLIENT_ID,
    payload: newValue,
  });
};

export const setDrChronoClientSecret = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DRCHRONO_CLIENT_SECRET,
    payload: newValue,
  });
};

export const setDrChronoRedirectUrl = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DRCHRONO_REDIRECT_URL,
    payload: newValue,
  });
};

export const setDrChronoWebhookSecret = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_DRCHRONO_WEBHOOK_SECRET,
    payload: newValue,
  });
};

export const setUseNevro = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_NEVRO,
    payload: newValue,
  });
};

export const setClinicPhoneNumber = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CLINIC_PHONENUMBER,
    payload: newValue,
  });
};

export const setUseMainstay = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_MAINSTAY,
    payload: newValue,
  });
};

export const setUseAdvancedMD = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_ADVANCEDMD,
    payload: newValue,
  });
};

export const setAdvancedMDUsername = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ADVANCEDMD_USERNAME,
    payload: newValue,
  });
};

export const setAdvancedMDPassword = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ADVANCEDMD_PASSWORD,
    payload: newValue,
  });
};

export const setAdvancedMDOfficeCode = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ADVANCEDMD_OFFICE_CODE,
    payload: newValue,
  });
};

export const setAdvancedMDAppName = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ADVANCEDMD_APPNAME,
    payload: newValue,
  });
};

export const setAdvancedMDInitialUrl = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ADVANCEDMD_INITIAL_URL,
    payload: newValue,
  });
};

export const setNevroSalesforceApiVersion = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_NEVRO_SALESFORCE_API_VERSION,
    payload: newValue,
  });
};

export const setNevroSalesforceClientId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_NEVRO_SALESFORCE_CLIENT_ID,
    payload: newValue,
  });
};

export const setNevroSalesforceClientSecret = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_NEVRO_SALESFORCE_CLIENT_SECRET,
    payload: newValue,
  });
};

export const setNevroSalesforceTokenUrl = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_NEVRO_SALESFORCE_TOKEN_URL,
    payload: newValue,
  });
};

export const setNevroSalesforceIntrospectUrl = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_NEVRO_SALESFORCE_INTROSPECT_URL,
    payload: newValue,
  });
};

export const setCustomQuestionDepartmentIds = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CUSTOM_QUESTION_DEPARTMENT_IDS,
    payload: newValue,
  });
};

export const setIsPortalProClinic = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_IS_PORTAL_PRO_CLINIC,
    payload: newValue,
  });
};

export const setUseGreenway = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_GREENWAY,
    payload: newValue,
  });
};

export const setGreenwayApiKey = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_API_KEY,
    payload: newValue,
  });
};

export const setGreenwayPracticeId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_PRACTICE_ID,
    payload: newValue,
  });
};

export const setGreenwayServiceCenterIds = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_SERVICE_CENTER_IDS,
    payload: newValue,
  });
};

export const setGreenwayProviderIds = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_PROVIDER_IDS,
    payload: newValue,
  });
};

export const setGreenwayUsername = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_USERNAME,
    payload: newValue,
  });
};

export const setGreenwayPassword = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_PASSWORD,
    payload: newValue,
  });
};

export const setGreenwayLicenseId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_LICENSE_ID,
    payload: newValue,
  });
};

export const setGreenwayUserLogon = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_USER_LOGON,
    payload: newValue,
  });
};

export const setGreenwayApplicationName = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_APPLICAITON_NAME,
    payload: newValue,
  });
};

export const setGreenwayMachineName = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_GREENWAY_MACHINE_NAME,
    payload: newValue,
  });
};

export const setGreenwayDocMappingDefault =
  (newGreenwayMappingDefault) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_GREENWAY_DOC_MAPPING_DEFAULT,
      payload: newGreenwayMappingDefault,
    });
  };

export const setGreenwayDocMappingPatientAgreement =
  (newGreenwayMappingPatientAgreement) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_GREENWAY_DOC_MAPPING_PATIENTAGREEMENT,
      payload: newGreenwayMappingPatientAgreement,
    });
  };

export const setGreenwayDocMappingOnePager =
  (newGreenwayMappingOnePager) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_GREENWAY_DOC_MAPPING_ONEPAGER,
      payload: newGreenwayMappingOnePager,
    });
  };

export const setGreenwayDocMappingScreener =
  (newGreenwayMappingScreener) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_GREENWAY_DOC_MAPPING_SCREENER,
      payload: newGreenwayMappingScreener,
    });
  };

export const setEnableMrnLookup = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_MRN_LOOKUP,
    payload: newValue,
  });
};

export const setAutoLogoutTime = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_AUTO_LOGOUT_TIME,
    payload: newValue,
  });
};

export const setCodePull = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.USE_CODE_PULL,
    payload: newValue,
  });
};

export const setEnablePortalPro = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_PORTAL_PRO,
    payload: newValue,
  });
};

export const setEnableDigitalShotConsent = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_DIGITAL_SHOT_CONSENT,
    payload: newValue,
  });
};

export const setEnablePsycEval = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ENABLE_PSYC_EVAL,
    payload: newValue,
  });
};

export const setShowNewAppointmentDashboard = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_SHOW_NEW_APPOINTMENT_DASHBOARD,
    payload: newValue,
  });
};
export const setCampaignEmails = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_CAMPAIGN_EMAILS,
    payload: newValue,
  });
};

export const setMappedAlgosClinicInstanceId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_MAPPED_ALGOS_CLINIC_INSTANCE_ID,
    payload: newValue,
  });
};

export const setUseEClinicalWorks = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_USE_ECLINICALWORKS,
    payload: newValue,
  });
};

export const setEClinicalWorksClientId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ECLINICALWORKS_CLIENT_ID,
    payload: newValue,
  });
};

export const setEClinicalWorksClientSecret = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ECLINICALWORKS_CLIENT_SECRET,
    payload: newValue,
  });
};

export const setEClinicalWorksResourceUrl = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ECLINICALWORKS_RESOURCE_URL,
    payload: newValue,
  });
};

export const setEClinicalWorksRedirectUrl = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ECLINICALWORKS_REDIRECT_URL,
    payload: newValue,
  });
};

export const setEClinicalWorksBulkClientId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ECLINICALWORKS_BULK_CLIENT_ID,
    payload: newValue,
  });
};

export const setEClinicalWorksBulkGroupId = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ECLINICALWORKS_BULK_GROUP_ID,
    payload: newValue,
  });
};

export const setEClinicalWorksPrivateKeyFileName = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ECLINICALWORKS_BULK_PRIVATE_KEY_FILE_NAME,
    payload: newValue,
  });
};

export const setEClinicalWorksPrivateKeyFile = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_ECLINICALWORKS_BULK_PRIVATE_KEY_FILE,
    payload: newValue,
  });
};

export const setPreferAthenaFHIR = (newValue) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SET_PREFER_ATHENA_FHIR,
    payload: newValue,
  });
};
