import {onGetDashboardData} from './patient/dashboard';
import {fetchFiltered as fetchFilteredPatients} from './patient/patient';
import {fetchFiltered as fetchFilteredAppointments} from './patient/patientappointments';
import {
  fetchSummary,
  setFilterPatientName as setReportSummaryFilterPatientName,
} from './reports/summary';
import {fetchByTemplateId} from './admin/perioperativemessagedetails';
import {fetchPatientsSpotlight} from './reports/patientsSpotlight';

export const SET_DOCTOR_ID = 'SET_DOCTOR_ID';
export const setDoctorId =
  (id, loadData = true) =>
  async (dispatch) => {
    dispatch({
      type: SET_DOCTOR_ID,
      payload: {id},
    });

    if (loadData) {
      // dispatch(onGetDashboardData());
      if (window.location.pathname === '/patients/list') {
        dispatch(fetchFilteredPatients({}));
      } else if (window.location.pathname === '/patients/appointments') {
        dispatch(fetchFilteredAppointments({}));
      } else if (window.location.pathname === '/reports/summary') {
        dispatch(fetchSummary());
        // dispatch(setReportSummaryFilterPatientName(''));
      } else if (window.location.pathname === '/pathways/') {
        dispatch(fetchByTemplateId());
      } else if (window.location.pathname === '/reports/patientsspotlight') {
        dispatch(fetchPatientsSpotlight());
      }
    }
  };
